.admin-container-switches {
    margin-bottom: 20px;
}

.admin-container-switches-btns {
    /* display: flex; */
    /* gap: 20px; */
}

.admin-container-switches-btn {
    display: flex;
    /* gap: 10px; */
    justify-content: space-between;
}

.admin-switces-title {
    font-size: 20px;
    font-weight: 600;
}

::-webkit-scrollbar {
    width: 4px;
    height: 0px;
    /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background color of the track */
}

::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    /* Color of the thumb */
    border-radius: 4px;
    /* Rounded corners of the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color of the thumb on hover */
}