.compare-container {
  box-shadow: 0px 1px 4px #13152329;
  border-radius: 6px;
  background-color: var(--background);
  padding: 20px 1rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* min-height: 85vh; */
}

.comp_chart {
  height: 450px;
}

.compare-container h5.ant-typography {
  margin-bottom: 0.2rem;
}

.compare-container h4.ant-typography {
  margin-top: 0;
  color: var(--text3);
}

.compare-container .search-boxes .ant-input-affix-wrapper svg {
  margin-right: 0;
  font-size: small;
  color: #000 !important;
}

.compare-container .search-boxes .ant-input-affix-wrapper {
  border-radius: 8px;
  width: 100%;
  height: 36px;
}
.compare-container
  .search-boxes
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
  width: 100%;
  height: 36px;
}

.compare-container .search-boxes .ant-picker {
  border-radius: 8px;
  width: 100%;
  height: 36px;
}

.compare-container .search-boxes .plus-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.compare-container .search-boxes .plus-btn {
  color: #025699;
  font-size: 36px;
}

.compare-container .graph-filter {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* border-bottom: 1px solid var(--text3); */
  /* margin-bottom: 20px; */
}

.compare-container .graph-filter .scale {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.compare-container .graph-filter .scale .scale-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
}

.compare-container .graph-filter .scale .scale-flex .suana {
  background: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /* border: 3px solid #0099FF; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  /* margin-right: 10px; */
}

.compare-container .graph-filter .scale .scale-flex .kitchen {
  background: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid #14fd00;
}

.compare-container .graph-filter .scale .scale-flex .kitchen3 {
  background: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid #dd243b;
}

.compare-container .graph-filter .scale .scale-flex .dining {
  background: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid #bb00fd;
}

.compare-container .graph-filter .scale .scale-flex .bar {
  background: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid #000000;
}

.compare-container .graph-filter .scale .scale-flex p {
  margin-bottom: 0;
  margin-top: 0;
}

.compare-container .graph-filter .filter-period {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0;
}

.compare-container .graph-filter .filter-period button {
  height: 22px;
  width: 74px;
  border: 1px solid #000000;
  border-radius: 4px;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #000;
}

.compare-container .graph-filter .filter-period .black-button {
  background-color: black !important;
  color: white !important;
}

.tree-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* gap: 6px; */
}

.blue-block {
  /* background: #0099FF; */
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 6px;
}

.tree-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tree-parent p {
  margin-top: 0;
  margin-bottom: 0;
}

.green {
  background: #14fd00;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.blue {
  /* background: black; */
  background: #5100fd;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 6px;
}

.red {
  background: #dd243b;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.pink {
  background: #bb00fd;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.black {
  background: #000;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.yellow {
  background: #f99600;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.green-block {
  background: #2ec276;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yellow-block {
  background: #f99600;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.red-block {
  background: #dd243b;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tree-title p {
  font-size: 12px;
}
.tree-parent p {
  font-size: 12px;
}
.date-range {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.date-range .ant-picker {
  border-radius: 8px;
  width: 172px;
  height: 36px;
  /* border: 1px solid #000; */
}

@media (max-width: 550px) {
  .compare-container .graph-filter .filter-period {
    justify-content: flex-start;
  }

  .compare-container .graph-filter .filter-period button {
    margin: 5px;
  }
}

.circle-wrap {
  /* margin: 150px auto; */
  width: 100px;
  height: 100px;
  background: #ccc;
  border-radius: 50%;

  /* border: 1px solid #cdcbd0; */
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
}

.circle-wrap .inside-circle {
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: white;
  /* line-height: 120px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 1em;
}

/* color animation */

/* 3rd progress bar */
.mask .fill {
  clip: rect(0px, 75px, 150px, 0px);
  background-color: red;
}

.mask.full,
.circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(135deg);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(135deg);
  }
}

@media (min-height: 850px) {
  .comp_chart {
    height: 600px;
  }
}
/*
@media (min-height: 840px) {
  .comp_chart {
    height: 630px;
  }
} */
