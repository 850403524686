.analysis-filters {
  margin: 30px 0;
  border-bottom: 1px solid #949494;
  padding-bottom: 10px;
  width: 100%;
}

.analysis-filters .radio-div p {
  margin: 0;
}

.analysis-filters .radio-div {
  background-color: white;
  border: 1px solid grey;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 55px;
  border-radius: 4px;
  border: 1px solid #d7dbec;
  color: #131523;
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  flex-wrap: wrap;
}

.analysis-filters .radio-div p {
  cursor: pointer;
  transition: 0.3 ease;
  min-width: 100px;
  min-height: 40px;
  padding: 5px;
  border-radius: 10px;
  vertical-align: middle;
  font-weight: bold;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1.2px;
  font-size: 14px;
}

.analysis-filters .radio-div .active {
  background-color: #37a1db;
  color: white;
}

.analysis-filters .right-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* drawer styling */

.analysis-drawer .ant-switch.ant-switch {
  height: 13px;
}

.analysis-drawer .ant-switch-handle.ant-switch-handle {
  top: -4px;
  /* left: 0; */
}

.analysis-drawer .filter-head {
  margin-top: 30px;
}

.analysis-drawer .filter-switch {
  margin: 10px 0;
}

.switches {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px
}

.colour-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
}

.colour-square {
  height: 12px;
  width: 12px;
}

.all-switches {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 37px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 4px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2EC276;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2EC276;
}

input:checked+.slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.analysis-by-dates-btn-group {
  display: flex;
  gap: 10px
}

.analysis-by-dates {
  border: 2px solid #000;
  padding: 5px 10px;
  border-radius: 10px;
}

.analysis-by-dates-active {
  background: black;
  color: white
}

.analysis-search {
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;
  background: white;
  width: fit-content;
  padding: 5px 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: #808193
}

.analysis-search-dropDown {
  position: absolute;
  top: 40px;
  padding: 5px 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  color: #000;
  background-color: #fff;
  z-index: 100;
  width: 100%;
  margin-left: -10px;
  height: 400px;
  overflow: auto;
}

.analysis-search-input {
  outline: none;
  border: none;
}

@media (max-width: 550px) {
  .analysis-filters .radio-div {
    justify-content: flex-start;
    padding: 10px;
  }
}