.battery-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: minmax(min-content, max-content);
    gap: 34px;
  }
  .battery-grid .battery-level {
    grid-column: 1/3;
    grid-row: 1/3;
    min-height: 300px !important;
  }
  .battery-grid .pv-production {
    grid-column: 3 /5;
    grid-row: 1/3;
  }
  .battery-grid .water-consuption {
    grid-column: 5/-1;
    grid-row: 1/3;
  }
  .battery-grid .demand-profile {
    grid-column: 1/5;
    grid-row: 3/13;
  }
  .battery-grid .forecast {
    grid-column: 5/-1;
    grid-row: 3/13;
  }
  .battery-grid .operation-profile {
    grid-column: 1/5;
    grid-row: 13/19;
  }
  .battery-grid .heat-map {
    grid-column: 5/-1;
    grid-row: 13/19;
  }
  .battery-white-card {
    box-shadow: 0px 1px 4px #13152329;
    border-radius: 6px;
    background-color: var(--background);
    padding: 20px 1rem;
  }